<template>
  <v-col>
    <v-card class="mt-4" flat tile :loading="fetchMixin.state === fetchMixin.STATES.LOADING">
      <v-card-text v-if="fetchMixin.state === fetchMixin.STATES.ERROR">
        <div>{{ $t('errors.basic') }}</div>
      </v-card-text>

      <v-card-text v-else-if="fetchMixin.state === fetchMixin.STATES.EMPTY">
        {{ $t('errors.unavailable', {name: $t('common.words.mevents')}) }}
      </v-card-text>

      <v-card-text v-else-if="fetchMixin.state === fetchMixin.STATES.IDLE || fetchMixin.state === fetchMixin.STATES.LOADING">
        <div v-if="fetchMixin.pagination && loaded">
          <notification-list
            :pagination="fetchMixin.pagination"
            @update-pagination="myUpdatePagination"
            :articles="articles"/>
        </div>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import FetchMixin from '@/mixins/fech.mixin';
import ArticleService from '@/services/ghost/article.service';
import NotificationList from '@/components/notification/NotificationList.vue';
import NotificationService from '@/services/api/notification.service';

export default {
  name: 'NotificationsView',
  components: {
    NotificationList,
  },
  mixins: [FetchMixin],
  data: () => ({
    articles: [],
    loaded: false,
  }),
  methods: {
    myUpdatePagination($event) {
      this.loaded = false;
      this.updatePagination($event);
    },
    fetchArticles() {
      const { page, size } = this.fetchMixin.pagination || { page: 1, size: 20 };
      return ArticleService.fetchArticles({ page, size });
    },
    addToList(article) {
      this.articles = [...this.articles, article];
    },
    getArticleNotificationDates() {
      NotificationService.getArticleNotifications()
        .then((result) => {
          const notified = result.data;
          notified.forEach((notification) => {
            const article = this.articles.find((a) => a.slug === notification.articleSlug);
            if (article) {
              article.notification_date = notification.notificationDate;
              article.week_from = notification.weekFrom;
              article.week_to = notification.weekTo;
              article.before = notification.beforeBirth;
            }
          });
          this.loaded = true;
        })
        .catch(() => {});
    },
  },
  created() {
    this.fetchMixin.config = {
      fetch: 'fetchArticles',
      afterFetch: 'getArticleNotificationDates',
      dataField: 'articles',
    };
    this.fetch();
  },
};
</script>

<style scoped>

</style>
