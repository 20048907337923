import CreatorService from '@/services/creator.service';
import AxiosApiService from '@/services/axios/axios.api.service';

const NotificationService = {
  ...CreatorService.createCrud(AxiosApiService, 'notification', 'notifications', '/notifications'),
  pushArticleNotification(slug, weekFrom = null, weekTo = null) {
    if (weekFrom !== null) {
      if (weekTo !== null) {
        return AxiosApiService.post(`notification/article/${slug}?weekFrom=${weekFrom}&weekTo=${weekTo}`, {});
      }
      return AxiosApiService.post(`notification/article/${slug}?weekFrom=${weekFrom}`, {});
    }
    return AxiosApiService.post(`notification/article/${slug}`, {});
  },
  saveArticleNotification(slug, weekFrom, weekTo) {
    if (weekTo === undefined || weekTo === null) {
      return AxiosApiService.put(`notification/article/${slug}?weekFrom=${weekFrom}`, {});
    }
    return AxiosApiService.put(`notification/article/${slug}?weekFrom=${weekFrom}&weekTo=${weekTo}`, {});
  },
  getArticleNotification(slug) {
    return AxiosApiService.get(`notification/article/${slug}`, {});
  },
  getArticleNotifications() {
    return AxiosApiService.get('notification/article', {});
  },
};

export default NotificationService;
