<template>
  <div>
    <div id="info-button">
      <v-btn icon color="info">
        <v-icon>mdi-information</v-icon>
      </v-btn>
      {{ $t('common.words.buttons') }}
      <div id="tooltip-button">
        <div style="display: flex; flex-direction: column">
          <div>
            <v-btn icon color="success">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
            <span>{{ $t('pages.notifications.save_params') }}</span>
          </div>
          <div>
            <v-btn icon color="info">
              <v-icon>mdi-bell</v-icon>
            </v-btn>
            <span>{{ $t('pages.notifications.save_params_and_notify') }}</span>
          </div>
          <div>
            <v-btn icon color="primary">
              <v-icon>mdi-bell</v-icon>
            </v-btn>
            <span>{{ $t('pages.notifications.notify_all') }}</span>
          </div>
        </div>
      </div>
    </div>

    <v-simple-table>
      <thead>
      <tr>
        <th class="text-left" width="30%">{{ $t('common.labels.title') }}</th>
        <th class="text-center" width="5%">{{ $t('common.labels.publication') }}</th>
        <th class="text-center" width="5%">{{ $t('common.labels.modification') }}</th>
        <th class="text-center" width="5%">{{ $t('common.labels.last_notification') }}</th>
        <th class="text-center">{{ $t('common.words.from') }}</th>
        <th class="text-center">{{ $t('common.words.to') }}</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(article) in articles" :key="article.slug">
        <td class="text-left"><b>{{ article.title }}</b><br> ({{ article.slug }})</td>
        <td class="text-center">{{ new Date(article.published_at).toLocaleDateString() }}</td>
        <td class="text-center">{{ new Date(article.updated_at).toLocaleDateString() }}</td>
        <td class="text-center" v-if="article.notification_date !== undefined && article.notification_date !== null">
          {{ new Date(article.notification_date).toLocaleString() }}
        </td>
        <td class="text-center" v-else>-</td>
        <td>
          <v-text-field
            :max="99"
            :min="0"
            v-model="article.week_from"
            single-line
            type="number"
          />
        </td>
        <td>
          <v-text-field
            :max="99"
            :min="0"
            v-model="article.week_to"
            single-line
            type="number"
          />
        </td>

        <td class="text-right">
          <v-btn icon color="success"
                 @click.stop="save(article.slug, article.week_from, article.week_to)">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-btn icon color="info"
                 @click.stop="notify(article.slug, article.week_from, article.week_to)">
            <v-icon>mdi-bell</v-icon>
          </v-btn>
          <v-btn icon color="primary"
                 @click.stop="notify(article.slug)">
            <v-icon>mdi-bell</v-icon>
          </v-btn>
        </td>
      </tr>
      </tbody>
    </v-simple-table>

    <paginator
      v-if="pagination"
      :pagination="pagination"
      @update="$emit('update-pagination', $event)"
    />
  </div>
</template>

<script>
import Paginator from '@/components/util/paginator.vue';
import NotificationService from '@/services/api/notification.service';
import FlashUtils from '@/utils/flash.utils';

export default {
  name: 'NotificationList',
  components: { Paginator },
  props: {
    articles: { type: Array, required: true },
    pagination: { type: Object, required: true },
  },
  methods: {
    save(slug, from, to) {
      NotificationService.saveArticleNotification(slug, from, to)
        .then(() => {
          FlashUtils.sendSuccess(this.$t('pages.notifications.send_param_success'));
        })
        .catch(() => {
          FlashUtils.sendError(this.$t('errors.basic'));
        });
    },
    notify(slug, from = null, to = null) {
      NotificationService.pushArticleNotification(slug, from, to)
        .then(() => {
          FlashUtils.sendSuccess(this.$t('pages.notifications.send_success'));
        })
        .catch(() => {
          FlashUtils.sendError(this.$t('errors.basic'));
        });
    },
  },
  created() {
  },
};
</script>

<style scoped>
#info-button {
  width: fit-content;
}

#tooltip-button {
  display: none;
  background-color: rgba(255, 255, 255);
  padding: 5px 16px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  position: fixed;
}

#info-button:hover #tooltip-button {
  display: block;
}
</style>
